.login-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("assets/login.jpg");
  width: 100%;
  height: 90vh;
  background-attachment: fixed;
  background-size: cover;
  justify-content: center;
}

.loginButton {
  margin: 0;
  margin-left: 1%;
}

.carosalImage {
  width: 100%;
  height: 55vh;
  object-fit: contain;
}

.fullWidth {
  width: 100%;
}

.Login {
  padding: 20px 0;
}

.imageCarosal {
  object-fit: contain;
}
.alertPositionDiv {
  position: fixed;
  top: 10%;
  right: 2%;
}

.loadingLoading {
  width: 100px;
  height: 50px;
}

.imgresponse {
  background-color: white;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100px;
  height: 60px;
}

.imgdiv {
  background-color: white;
  width: 100%;
}
.logout {
  float: right;
}

.navLeftDiv {
  display: flex;
  align-items: center;
  gap: 20px;
}

@media only screen and (max-width: 600px) {
  .navLeftDiv {
    align-items: flex-start;
    flex-direction: column;
    gap: 5px;
  }
}

.bottomDiv {
  display: flex;
  justify-content: flex-end;
}

h5 {
  text-align: center;
}
