.vertical {
  height: "100%";
  width: 1;
  background-color: black;
}
label {
  margin-right: 15px;
}
input {
  margin-left: 10px;
}

.h {
  margin-top: 1px;
}
button {
  margin-left: 50px;
}
.slider {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
}
.image {
  width: 650px;
  height: 500px;
  border-radius: 10px;
}

.right-arrow {
  font-size: 3rem;
  color: black;
  cursor: pointer;
}
.left-arrow {
  font-size: 3rem;
  color: black;
  cursor: pointer;
}

.actionButtonDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.emptySelectBox {
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

nav {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

