@import url('https://fonts.googleapis.com/css2?family=Cinzel&family=Montserrat&display=swap');
/* font-family: 'Cinzel', serif;
font-family: 'Montserrat', sans-serif; */


*, ::after, ::before {
  font-family: Cinzel;
}

/* Banner */
.contest-box .banner {
  background-color: white;
  background-image: url("./assets/Header_web_BG.jpg");
  height: 600px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.contest-box .banner-box {
  background: linear-gradient(to right, #ffffff 4px, transparent 4px) 0 0,
    linear-gradient(to right, #ffffff 4px, transparent 4px) 0 100%,
    linear-gradient(to left, #ffffff 4px, transparent 4px) 100% 0,
    linear-gradient(to left, #ffffff 4px, transparent 4px) 100% 100%,
    linear-gradient(to bottom, #ffffff 4px, transparent 4px) 0 0,
    linear-gradient(to bottom, #ffffff 4px, transparent 4px) 100% 0,
    linear-gradient(to top, #ffffff 4px, transparent 4px) 0 100%,
    linear-gradient(to top, #ffffff 4px, transparent 4px) 100% 100%;

  background-repeat: no-repeat;
  background-size: 50px 50px;
}

.contest-box .banner-box h1 span {
  font-size: calc(0.8rem + 1.5vw);
  display: flex;
  flex-direction: column;
}

.contest-box .banner h2 {
  font-family: Montserrat;
}

/* Award */
.award-section {
  background-color: white;
  background-image: url("./assets/Award_web_BG.jpg");
  height: 475px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: auto;
}

.award-section h1 {
  overflow: hidden;
  margin-top: -44px;
  padding-bottom: 30px;
}

.award-section h1:before,
.award-section h1:after {
  background-color: #000;
  content: "";
  display: inline-block;
  height: 3px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

.award-section h1:before {
  right: 1em;
  margin-left: -50%;
}

.award-section h1:after {
  left: 1em;
  margin-right: -50%;
}

.award-section .row {
  border: 3px solid #000;
  border-top: unset;
}

.award-section .card-block {
  border-right: 1px solid #000000;
  border-radius: unset;  
}

.award-section .card-block:last-child {
  border-right: unset;
}

.award-section .card-block .card-body h5,
.award-section p {
  font-family: Montserrat;
}

/* deadline */
.deadline-section {
  background-color: white;
  background-image: url("./assets/Deadline_BG.jpg");
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.deadline-section p {
  font-family: Montserrat;
}

/* Theme */
.theme.award-section .row {
  margin: 0 10rem;
}

.theme.award-section {
  background-color: white;
  background-image: url("./assets/Theme_web_BG.jpg");
  height: auto;
  min-height: 600px;
}

.theme.award-section .theme-block .card-body h5,
.theme.award-section p {
  font-family: Montserrat;
}

/* rule */
.rule-section .accordion-item {
  margin-bottom: 2rem;
}

.rule-section * {
  font-family: Montserrat;
}

.rule-section .accordion-item:not(:first-of-type) {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.rule-section .accordion-item,
.rule-section .accordion-button {
  border-radius: 8px !important;
}

.rule-section .accordion-button:not(.collapsed) {
  color: #000000 !important;
  background-color: unset !important;
}

.rule-section .accordion-button:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.25) !important;
}

.rule-section .accordion-button:not(.collapsed)::after {
  filter: grayscale(1);
}

.rule-section .accordion-button:focus {
  border-color: #000000 !important;
}

.rule-section .accordion-button::after {
  margin-left: 30px;
}

.rule-section .accordion-body {
  background-color: #f5f5f5;
}

.list-style-type-lower-alpha {
  list-style-type: lower-alpha;
}

/* Form */
.form-section {
  background-color: white;
  background-image: url("./assets/Form_web_BG.jpg");
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.form-section form * {
  font-family: Montserrat;
}

.form-section .verticalLine {
  border-right: 1px solid #ffffff;
}

.form-section .verticalLine:last-child {
  border-right: unset;
}

.form-check .form-check-label {
  text-align: left;
}

/* Responsive */
@media only screen and (max-device-width: 992px) {
  /* Banner */
  .contest-box .banner {
    /* background-image: url("./assets/Header_Mobile_BG.jpg"); */
    background-position: center center;
  }

  .contest-box .banner-box {
    align-self: flex-end;
  }

  /* Award */
  .award-section {
    background-image: url("./assets/Award_mobile_BG.jpg");
    height: auto;
  }

  .award-section h1 {
    margin-top: -5.5%;
  }

  .award-section h1:before {
    top: -3px;
  }

  .award-section h1:after {
    left: 2em;
    margin-right: -50%;
    top: -3px;
  }

  .award-section .card-block {
    border-right: unset;
  }

  .award-section .card {
    border-bottom: 1px solid #000000 !important;
    border-radius: unset;
  }

  .no-border.second-last {
    border: unset !important;
  }

  .no-border.last {
    border: unset !important;
  }

  /* Theme */
  .theme.award-section .row {
    margin: 0;
  }

  .theme.award-section {
    background-color: white;
    background-image: url("./assets/Theme_mobile_BG.jpg");
    height: auto;
  }

  .theme.award-section .card {
    border-right: 1px solid #000000 !important;
    border-bottom: unset !important;
    border-radius: unset;
  }

  .theme .no-border.last {
    border: unset !important;
  }

  /* Form */
  .form-section {
    background-image: url("./assets/Form_mobile_BG.jpg");
    height: auto;
  }

  .form-section .verticalLine {
    border-right: unset;
    border-bottom: 1px solid #ffffff;
    margin-bottom: 30px;
  }

  .form-section .verticalLine:last-child {
    border-bottom: unset;
  }

  .form-section .form-btn {
    text-align: center !important;
  }
}

@media only screen and (max-device-width: 768px) {
  /* Banner */
  .contest-box .banner {
    background-image: url("./assets/Header_Mobile_BG.jpg");
    background-position: center center;
  }

  /* Award */
  .award-section {
    margin-top: 5rem;
  }

  .award-section h1 {
    margin-top: unset;
  }

  .award-section h1:before {
    margin-left: 0;
    left: 0rem;
    position: absolute;
    top: 28px;
    width: 20%;
  }

  .award-section h1:after {
    position: absolute;
    width: 20%;
    right: 0;
    margin: 0;
    left: unset;
    top: 28px;
  }

  .award-section h1 {
    margin-top: -44px;
    overflow: hidden;
    padding-bottom: 30px;
    position: relative;
  }

  .award-section .card {
    border-bottom: 1px solid #000000 !important;
    border-radius: unset;
  }

  .no-border.second-last {
    border-bottom: 1px solid #000000 !important;
  }

  .no-border.last {
    border: unset !important;
  }

  /* Theme */
  .theme.award-section .row {
    margin: 0;
  }

  .theme.award-section .card {
    border-bottom: 1px solid #000000 !important;
    border-right: unset !important;
    border-radius: unset;
  }

  .theme .no-border.last {
    border: unset !important;
  }
}

@media only screen and (max-device-width: 576px) {
  .award-section h1 {
    font-size: 42px;
  }

  .award-section h1:after {
    width: 15%;
  }

  .award-section h1:before {
    width: 15%;
  }
}

@media only screen and (max-device-width: 414px) {
  .award-section h1 {
    font-size: 30px;
  }

  .award-section h1:after {
    width: 15%;
  }

  .award-section h1:before {
    width: 15%;
  }
}

@media only screen and (max-device-width: 375px) {
  .award-section h1 {
    font-size: 26px;
  }
}

@media only screen and (max-device-width: 280px) {
  .award-section {
    margin-top: 0;
  }

  .award-section h1 {
    padding: 0;
    margin-top: -24.4%;
  }

  .award-section h1.section-title {
    letter-spacing: 1px;
  }

  /* .award-section h1::after {
    top: -60.5%;
    left: 0;
  }

  .award-section h1::before {
    top: -10.5%;
  } */

  /* .theme.award-section h1 {
    margin-top: -24.4%;
  } */

  /* .theme.award-section h1::before {
    top: -33.5%;
  }

  .theme.award-section h1::after {
    top: -33.5%;
    left: 1rem;
  } */

  .rule-section .accordion-button::after {
    margin-left: auto;
  }
}
